import styles from '../versiontwo.module.css'

const VersionTextTwo = () => {
    return ( 
        <>
        <div className={styles.versionboxtwo} >
            <h1>V.O.O.2</h1>
            <h3>Last Updated on</h3>
            <h2> <span className={styles.highlight}>May</span> 2022</h2>
        </div>
        </>
     );
}

export default VersionTextTwo;